//Enums
export enum localeOptions {
  en = 'en',
  de = 'de',
  sk = 'sk'
}

//Constants
export const siteName = "JMK Dev";
export const defaultFallbackLanguage = localeOptions.en;
