import { StrictMode } from 'react';
import { Global, ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './app/App';
import { globalCss, JMKTheme } from './styles';
import { initializeI18n } from '@jmkdev/locales';

initializeI18n();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <App />
    ),
  },
]);

root.render(
  <StrictMode>
    <ThemeProvider theme={JMKTheme}>
      <CssBaseline />
      <Global styles={globalCss} />
      <RouterProvider router={router} />
    </ThemeProvider>
  </StrictMode>
);
