import { Typography } from '@mui/material';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import { DefaultFallback } from '@shared/components';

import { routes } from './modules';

const App = () => {
  const body = useRoutes([
    {
      path: '/',
      children: routes,
    },
  ]);
  
  return (
    <Suspense fallback={<DefaultFallback />}>{body}</Suspense>
  );
};

export default App;
