import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, de, sk } from '../translations';
import { defaultFallbackLanguage } from '@jmkdev/utils';

export const initializeI18n = (): void => {
  use(initReactI18next) // passes i18n down to react-i18next
    .init({
      appendNamespaceToMissingKey: true,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      fallbackLng: defaultFallbackLanguage,
      parseMissingKeyHandler: (missingKey: string) => {
        const [ns, key] = missingKey.split(':');

        return `Missing translation "${key}" or namespace "${ns}" in resources`;
      },
      resources: {
        en,
        de,
        sk,
      },
    });
};
