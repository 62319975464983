import { SxProps } from "@mui/material";

export const loaderSx: SxProps = {
    border: '16px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '16px solid #3498db',
    width: '120px',
    height: '120px',
    position: 'relative',
    animation: 'spin 2s linear infinite',
    margin: '20px auto',
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
};

export const duckIconSx: SxProps = {
transform: 'rotate(180deg)',
position: 'absolute',
left: '35%',
top: '-2px',
};