import { common as common_en } from './en';
import { common as common_de } from './de';
import { common as common_sk } from './sk';

export const commonEn = {
  common: common_en,
};

export const commonDe = {
  common: common_de,
};

export const commonSk = {
  common: common_sk,
};