import { commonEn, commonDe, commonSk } from './common';

export const en = {
  ...commonEn,
};

export const de = {
  ...commonDe,
};

export const sk = {
  ...commonSk,
};

export { useTranslations } from './useTranslations';