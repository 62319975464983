import { lazy } from "react";
import { RouteObject } from 'react-router-dom';

const WorkInProgress = lazy(() => import('./pages/WorkInProgress/WorkInProgress').then((module) => ({ default: module.WorkInProgress })));

export const workInProgressRoutes: RouteObject[] = [
  {
    index: true,
    element: <WorkInProgress />,
  },
];
