import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import merge from 'lodash.merge';

import { globalFonts } from './fonts';

export const globalCss = () => [
  css({
    'html, body, #root': {
      height: '100%',
      width: '100%',
    },
  }),
  globalFonts,
];

const theme = createTheme();


export const JMKTheme = merge(
  { ...theme },
  {
    components: {},
  }
);
